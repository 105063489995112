<p-pickList
  [sourceStyle]="{ height: '30rem' }"
  [targetStyle]="{ height: '30rem' }"
  breakpoint="1000px"
  [responsive]="true"
  [source]="filteredRelations"
  [target]="selectedRelations"
  sourceHeader="Bezug wählen"
  targetHeader="Zugeordnet"
  [dragdrop]="true"
  filterBy="label"
  [showSourceControls]="false"
  [showTargetControls]="false"
  [showTargetFilter]="false"
  (onMoveToTarget)="onItemAdded($event.items)"
  (onMoveToSource)="onItemRemoved($event.items)"
  (onMoveAllToSource)="onItemRemoved($event.items)"
  (onMoveAllToTarget)="onItemAdded($event.items)">
  <ng-template let-item pTemplate="sourceHeader">
    <p-tabs
      [(value)]="typeFilterSingle"
      (valueChange)="filterRelationTypes($event)">
      <p-tablist>
        <p-tab *ngFor="let type of relationTypes" [value]="type.value">
          <i [ngClass]="type.icon" class="p-tabmenu-icon"></i>
          <span>{{ type.label }}</span>
        </p-tab>
      </p-tablist>
    </p-tabs>
  </ng-template>
  <ng-template let-item pTemplate="item">
    <eva-relation-item [relation]="item"></eva-relation-item>
  </ng-template>
</p-pickList>
