<div class="h-full" *ngIf="organisationForm">
  <form
    [formGroup]="organisationForm"
    id="edit-organisation-form"
    (ngSubmit)="submitForm()"
    style="
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    ">
    <p-tabs value="0">
      <p-tablist>
        <p-tab value="0"
          ><i class="pi pi-building mr-2"></i>
          <span
            i18n="@@organisation-edit.Allgemein"
            data-cy="name"
            [style.color]="this.organisationForm.controls.general.invalid?'red':null"
            >Name<span *ngIf="this.organisationForm.controls.general.invalid"
              >!</span
            >
            <span *ngIf="this.organisationForm.controls.general.dirty"
              >*</span
            ></span
          ></p-tab
        >
        <p-tab value="1"
          ><i class="pi pi-map mr-2"></i>
          <span
            i18n="@@organisation-edit.address"
            data-cy="address"
            [style.color]="this.organisationForm.controls.address.invalid?'red':null"
            >Addresse
            <span *ngIf="this.organisationForm.controls.address.invalid"
              >!</span
            >
            <span *ngIf="this.organisationForm.controls.address.dirty"
              >*</span
            ></span
          ></p-tab
        >
        <p-tab value="2">
          <i class="pi pi-id-card mr-2"></i>
          <span
            i18n="@@organisation-edit.contact"
            data-cy="contact"
            [style.color]="this.organisationForm.controls.contact.invalid?'red':null"
            >Kontakt
            <span *ngIf="this.organisationForm.controls.contact.invalid"
              >!</span
            >
            <span *ngIf="this.organisationForm.controls.contact.dirty">*</span>
          </span></p-tab
        >
        <p-tab
          value="3"
          *ngIf="![roleEnum.PROJECT_MANAGER, roleEnum.CONSULTANT].includes(currentRole ?? roleEnum.ANONYMOUS)">
          <i class="pi pi-user mr-2"></i>
          <span
            i18n="@@organisation-edit.templates"
            data-cy="templates"
            [style.color]="this.organisationForm.controls.textTemplates.invalid?'red':null"
            >Text-Vorlagen<span
              *ngIf="this.organisationForm.controls.textTemplates.invalid"
              >!</span
            >
            <span *ngIf="this.organisationForm.controls.textTemplates.dirty"
              >*</span
            ></span
          ></p-tab
        >
      </p-tablist>
      <p-tabpanels>
        <p-tabpanel value="0" formGroupName="general">
          <div class="fluid formgrid grid">
            <div class="field col">
              <p-floatlabel variant="on">
                <input
                  id="name"
                  class="w-full"
                  pInputText
                  formControlName="name" />
                <label for="name" i18n="@@name">Name*</label>
              </p-floatlabel>
            </div>
          </div>
          <div class="field mb-2 mt-0">
            <label
              i18n-pTooltip="@@organisation.description.ttip"
              pTooltip="Beschreiben Sie Ihre Organisation in 100 Wörtern.">
              <span i18n="@@description">Beschreibung</span>
            </label>
            <textarea
              id="description"
              class="w-full"
              pTextarea
              formControlName="description"
              rows="5"
              placeholder="Beschreibung"
              i18n-placeholder="@@description"></textarea>
          </div>
          <div
            class="w-full"
            *ngIf="currentRole === roleEnum.ADMIN && (consultancyOptions??[]).length">
            <p-select
              [options]="consultancyOptions"
              [showClear]="true"
              placeholder="Berater (optional)"
              formControlName="consultancy"
              optionLabel="name"
              optionDisabled="disabled"
              optionValue="id"
              appendTo="body"
              class="cy-auditor-select">
            </p-select>
          </div>
        </p-tabpanel>
        <p-tabpanel value="1" formGroupName="address">
          <div class="fluid">
            <div class="fluid formgrid grid">
              <div class="field col-3">
                <p-floatlabel variant="on">
                  <input
                    id="zip"
                    class="w-full"
                    pInputText
                    formControlName="zip" />
                  <label for="zip" i18n="@@plz">PLZ*</label>
                </p-floatlabel>
              </div>
              <div class="field col-9">
                <p-floatlabel variant="on">
                  <input
                    id="city"
                    class="w-full"
                    pInputText
                    formControlName="city" />
                  <label for="city" i18n="@@city">Ort*</label>
                </p-floatlabel>
              </div>
            </div>
            <div class="fluid formgrid grid">
              <div class="field col">
                <p-floatlabel variant="on">
                  <input
                    id="address"
                    class="w-full"
                    pInputText
                    formControlName="address"
                    placeholder="Straße Nr." />
                  <label for="address" i18n="@@address">Addresse*</label>
                </p-floatlabel>
              </div>
            </div>
          </div>
        </p-tabpanel>
        <p-tabpanel value="2" formGroupName="contact">
          <div class="fluid formgrid grid">
            <div class="field col">
              <p-floatlabel variant="on">
                <input
                  id="website"
                  class="w-full"
                  pInputText
                  formControlName="website" />
                <label for="website" i18n="@@website">Website</label>
              </p-floatlabel>
            </div>
          </div>

          <hr />
          <div class="fluid formgrid grid">
            <div class="field col">
              <p-floatlabel variant="on">
                <input
                  id="contact"
                  class="w-full"
                  pInputText
                  formControlName="contact"
                  placeholder="Name" />
                <label for="contact" i18n="@@organisation.contact"
                  >Ansprechpartner*</label
                >
              </p-floatlabel>
            </div>
          </div>

          <div class="fluid formgrid grid">
            <div class="field col-12 lg:col-6">
              <p-floatlabel variant="on">
                <input
                  id="contactMail"
                  class="w-full"
                  pInputText
                  formControlName="contactMail" />
                <label for="contactMail" i18n="@@contactMail"
                  >Mail Ansprechpartner*</label
                >
              </p-floatlabel>
            </div>
            <div class="field col-12 lg:col-6">
              <p-floatlabel variant="on">
                <input
                  id="contactPhone"
                  class="w-full"
                  pInputText
                  formControlName="contactPhone" />
                <label for="contactPhone" i18n="@@contactPhone"
                  >Tel. Ansprechpartner</label
                >
              </p-floatlabel>
            </div>
          </div>

          <hr />

          <div class="fluid formgrid grid">
            <div class="field col-12">
              <p-floatlabel variant="on">
                <input
                  id="internalContact"
                  class="w-full"
                  pInputText
                  formControlName="internalContact" />
                <label for="internalContact" i18n="@@internalContact"
                  >Interner Ansprechpartner</label
                >
              </p-floatlabel>
            </div>
          </div>
        </p-tabpanel>

        <p-tabpanel
          value="3"
          *ngIf="![roleEnum.PROJECT_MANAGER, roleEnum.CONSULTANT].includes(currentRole ?? roleEnum.ANONYMOUS)">
          <p-button
            class="mb-2"
            (click)="newTextTemplateClicked()"
            label="Neue Vorlage..."></p-button>
          <p-scrollPanel [style]="{ width: '100%', height: '373px' }">
            <div>
              <div
                *ngFor="let textTemplateForm of organisationForm.controls.textTemplates.controls">
                <form
                  [formGroup]="textTemplateForm"
                  class="fluid formgrid grid">
                  <div
                    class="field col-11 mb-0"
                    *ngIf="textTemplateForm?.controls?.name as namefb">
                    <p-floatlabel variant="on">
                      <input
                        id="name"
                        class="w-full"
                        pInputText
                        [formControl]="namefb" />
                      <label for="name" i18n="@@name">Name*</label>
                    </p-floatlabel>
                  </div>
                  <div class="field col-1 mb-0 pl-0 flex align-items-center">
                    <p-button
                      [style]="{'width': '2rem', 'height':'2rem'}"
                      [rounded]="true"
                      [text]="false"
                      [outlined]="true"
                      icon="pi pi-times"
                      (click)="deleteTextTemplate(textTemplateForm)"></p-button>
                  </div>
                  <div
                    class="field col-12 mb-0"
                    *ngIf="textTemplateForm?.controls?.text">
                    <eva-editor
                      #conclusionEditor
                      [editorStyle]="{ 'font-size': '1.1rem' }"
                      [formControlName]="'text'">
                      <span class="ql-formats">
                        <button
                          type="button"
                          (click)="variablesPopover.toggle($event)">
                          <i class="pi pi-key"></i>
                        </button>
                      </span>
                    </eva-editor>
                    <p-popover [style]="{'z-index': '9999'}" #variablesPopover>
                      <div class="variable-list">
                        <div
                          class="variable-item"
                          *ngFor="let ttVar of textTemplateService.Variables; trackBy: trackByFn;"
                          (click)="insertVariable(variablesPopover, conclusionEditor.editor, ttVar.variable)">
                          <div class="name">{{ttVar.name}}</div>
                          <div class="value" *ngIf="sampleCertification$">
                            {{
                            textTemplateService.replaceVariablesInTextTemplate(ttVar.variable,
                            (sampleCertification$ | async))}}
                          </div>
                        </div>
                      </div>
                    </p-popover>
                  </div>
                </form>

                <hr style="border-top-color: var(--p-primary-color)" />
              </div>
            </div>
          </p-scrollPanel>
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>
  </form>
</div>
