/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import { registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import {
  APP_INITIALIZER,
  ErrorHandler,
  inject,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import '@angular/localize/init';

import { InMemoryCache } from '@apollo/client/core';
import { provideApollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { ReactiveFormsModule } from '@angular/forms';
import { CertificationProcessService } from '@eva/certification/service';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsModule } from '@ngxs/store';

import * as Sentry from '@sentry/angular';

import {
  ENVIRONMENT,
  EnvironmentService,
} from './services/environment.service';

import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';

import { OAuthInterceptor } from '../app/interceptors/oauth.interceptor';
import { AppRoutingModule } from './app.routing.module';
import { AuthGuard } from './guards/authGuard';
import { CertificationApiService } from './services/api/certification.api.service';
import { FileService } from './services/api/file.service';
import { ProjectService } from './services/api/project.service';
import { UserService } from './services/api/user.service';
import { AuthenticationService } from './services/authentication.service';
import { AuthorizationService } from './services/authorization.service';
import { LoadingService } from './services/loading.service';
import { AppState } from './states/app.state';

import { CookieService } from 'ngx-cookie-service';
import { MenuService } from './components/layout/services/menu.service';
import { SystemService } from './services/api/system.service';
import { MapService } from './services/map.service';

import { AppComponent } from './app.component';
import { MenuComponent } from './components/layout/components/menu/menu.component';
import { MenuitemComponent } from './components/layout/components/menuitem/menuitem.component';
import { TopBarComponent } from './components/layout/components/topbar/topbar.component';
import { MainComponent } from './components/layout/main.component';
import { EditOrganisationComponent } from './components/organisation/edit/edit.organisation.component';

import { ComponentsModule } from './components/shared/components.module';
import { PrimengModule } from './components/shared/primeng.module';
import { SharedComponentsModule } from './components/shared/shared-components.module';
import { MessagingService } from './services/messaging.service';

import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { evaThemeProviders } from '@eva/ng-base';
import { environment } from '../environments/environment';
import { ScrollSpyDirective } from './directives/scroll-spy.directive';
import { OfflineInterceptor } from './interceptors/offline.interceptor';
import { TimeoutInterceptor } from './interceptors/timeout.interceptor';
import { HistoryService } from './services/api/history.service';
import { EditSiteService } from './services/edit-site.service';
import { OfflineDbService } from './services/offline-db.service';
import { OfflineService } from './services/offline.service';
import { TextTemplateService } from './services/text-template.service';
import { ThemeService } from './services/theme.service';
import { HistoryState } from './states/history.state';

registerLocaleData(localeDE, 'de');

/**
 * make this available anywhere
 *
 * @param appInitializerService
 */
export function appAuthServiceFactory(initializeService: OfflineService) {
  return () => initializeService.initialize();
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    TopBarComponent,
    MenuComponent,
    MenuitemComponent,
    EditOrganisationComponent,
    ScrollSpyDirective,
  ],
  imports: [
    NgxsModule.forRoot([AppState, AppState, AppState, HistoryState], {
      developmentMode: !environment.production,
      selectorOptions: {
        injectContainerState: false,
        suppressErrors: false,
      },
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    OAuthModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ComponentsModule,
    PrimengModule,
    SharedComponentsModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled:
        environment.production &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ((navigator as any)['standalone'] ||
          window.matchMedia('(display-mode: standalone)').matches ||
          window.location.href.indexOf('install=pwa') > 0),
      // Register the ServiceWorker as soon as the application is stable
      // or after 3 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:3000',
    }),
  ],
  providers: [
    EnvironmentService,
    MenuService,
    FileService,
    UserService,
    ProjectService,
    CertificationApiService,
    AuthenticationService,
    AuthorizationService,
    HistoryService,
    OAuthService,
    MessagingService,
    MapService,
    CookieService,
    LoadingService,
    OfflineService,
    OfflineDbService,
    AuthGuard,
    CertificationProcessService,
    EditSiteService,
    SystemService,
    ThemeService,
    TextTemplateService,
    evaThemeProviders(),
    provideApollo(() => {
      const httpLink = inject(HttpLink);
      return {
        cache: new InMemoryCache({
          addTypename: false,
        }),
        link: httpLink.create({
          uri: environment.apiUrl,
        }),
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
          },
        },
      };
    }),
    // { provide: ThemeService, deps: [APP_INITIALIZER] },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appAuthServiceFactory,
      multi: true,
      deps: [OfflineService, AuthenticationService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OfflineInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeoutInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
