<p-table
  data-cy="document-table"
  #docsTable
  [value]="documentList"
  styleClass="p-datatable-gridlines p-datatable-sm"
  dataKey="category"
  [rowGroupMode]="(showGroupHeader || (!documentsExists && !showTableHeader))?'subheader':undefined"
  groupRowsBy="category"
  sortField="category"
  sortMode="single"
  [globalFilterFields]="['name']"
  showGridlines>
  <ng-template pTemplate="caption">
    <div *ngIf="showTableHeader" class="flex justify-content-between gap-2">
      <p-iconfield>
        <p-inputicon styleClass="pi pi-search" />
        <input
          pInputText
          type="text"
          (input)="docsTable.filterGlobal($any($event.target).value, 'contains')"
          placeholder="Filter" />
      </p-iconfield>
      <div class="flex gap-2">
        <p-button
          i18n-label="@@documents-download-zip"
          icon="pi pi-download"
          data-cy="download-zip"
          label="Alle Dokumente herunterladen (ZIP)"
          *ngIf="showZipDownload && certificationId"
          (onClick)="downloadZip(certificationId)"></p-button>

        @if (editMode) {
        <p-button
          i18n-pTooltip="@@documents-upload-document"
          [pTooltip]="'Dokumente hochladen'"
          icon="pi pi-upload"
          data-cy="add-file"
          (onClick)="documentNew()"></p-button>

        @if( documentsLinked.observed) {
        <p-button
          i18n-pTooltip="@@documents-link-document"
          [pTooltip]="'Dokumente verknüpfen'"
          icon="pi pi-link"
          data-cy="link-file"
          (onClick)="linkFileClick()"></p-button>
        } }
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr *ngIf="getCount(documentList)>0">
      <th style="width: 1px; height: 2.75rem; min-width: 3rem"></th>
      <th pSortableColumn="name" colspan="3" style="width: 60%">
        <span i18n="@@name">Name</span> <p-sortIcon field="name"></p-sortIcon>
      </th>

      <th style="width: 7rem" i18n="@@size">Größe</th>
      <th style="width: 5rem" i18n="@@visibility">Sichtbarkeit</th>
      <th style="width: 6rem" pSortableColumn="createdAt">
        <span i18n="@@date">Datum</span>
        <p-sortIcon field="createdAt"></p-sortIcon>
      </th>
      <ng-container *ngIf="editMode">
        <th style="width: 3.775rem"></th>
        <th style="width: 3.775rem"></th>
      </ng-container>
      <th style="min-width: 50px">
        <ng-container
          *ngIf="editMode && (!showGroupHeader || (!documentsExists && !showTableHeader))">
          <ng-container *ngTemplateOutlet="addButtonTemplate"></ng-container
        ></ng-container>
      </th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="groupheader"
    let-document
    let-rowIndex="rowIndex"
    let-expanded="expanded">
    <tr
      style="background-color: var(--p-primary-50)"
      data-cy="group-header-row">
      <td style="height: 50px; min-width: 50px">
        <p-button
          #rowExpandButton
          *ngIf="getCount(documentList, document.category)>0"
          pRipple
          [pRowToggler]="document"
          size="small"
          class="p-button-text"
          [rounded]="true"
          [text]="true"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></p-button>
      </td>
      <td [attr.colspan]="editMode === true ? 8 : 6" style="width: 100%">
        <div class="group-header">
          <span class="font-bold ml-2"
            >{{getLocalization(document.category)}}</span
          >
          <span
            class="font-bold mr-2"
            *ngIf="getCount(documentList, document.category)>0"
            >{{getCount(documentList, document.category)}}</span
          >
        </div>
      </td>
      <td>
        @if (showAddCategoryDocument) {
        <ng-container
          *ngTemplateOutlet="addButtonTemplate; context: {$implicit: document}"></ng-container>
        }
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-document *ngIf="showGroupHeader">
    <ng-container
      *ngTemplateOutlet="rowTemplate; context: {$implicit: document}"></ng-container>
  </ng-template>

  <ng-template pTemplate="body" let-document *ngIf="!showGroupHeader">
    <ng-container
      *ngTemplateOutlet="rowTemplate; context: {$implicit: document}"></ng-container>
  </ng-template>

  <ng-template pTemplate="summary" *ngIf="showNoDocumentsMessage">
    <div
      *ngIf="getCount(documentList)===0 && loaded"
      class="flex justify-content-around">
      <div class="flex align-items-center gap-2 p-3">
        <i class="pi pi-info-circle"></i>
        <span i18n="@@shared.no-documents-long">
          Noch keine Dokumente vorhanden</span
        >
      </div>
    </div>
  </ng-template>
</p-table>

<ng-template #rowTemplate let-document>
  <tr *ngIf="!document.fake" data-cy="group-row">
    <td style="text-align: center">
      <i class="pi {{ getIconClass(document) }} text-2xl"></i>
    </td>
    <td style="width: 70%">
      <div class="flex justify-content-between">
        <div>
          <span
            pTooltip="Verknüpfungen zu diesem Dokument"
            i18n-pTooltip="@@document.relation-count-tooltip"
            tooltipPosition="top"
            style="
              padding: 0 0.5rem;
              margin: 0 0.5rem;
              font-weight: 800;
              background-color: var(--color-2-green);
              color: white;
            ">
            {{document.relations?.length ?? 0}}</span
          >
          <span> {{document.name}}</span>
        </div>
      </div>
    </td>
    <td style="width: 1px">
      <div *ngIf="(document.remarks??'').length>0">
        <p-popover #op> {{document.remarks}} </p-popover>
        <p-button
          (click)="op.toggle($event)"
          icon="pi pi-info-circle"
          [rounded]="true"
          [text]="true"></p-button>
      </div>
    </td>
    <td>
      @if (document.groupCertificationId) {
      <p-badge
        [value]="'G'"
        severity="info"
        pTooltip="Dokument einer Gruppenzertifizierung" />
      }
    </td>
    <!-- <td>{{document.remarks}}</td> -->
    <td>{{ formatSize(document.file?.fileSize) }}</td>
    <td style="text-align: center">
      <i
        [style.color]="'green'"
        class="pi pi-eye"
        i18n-pTooltip="@@document.public-visible-tooltip"
        pTooltip="öffentlich sichtbar"
        *ngIf="document.visibility === documentVisibilityEnum.PUBLIC"></i>
      <i
        [style.color]="'gray'"
        class="pi pi-eye-slash"
        i18n-pTooltip="@@document.private-visible-tooltip"
        pTooltip="nicht öffentlich"
        *ngIf="document.visibility !== documentVisibilityEnum.PUBLIC"></i>
    </td>
    <td>{{ document.createdAt | date : 'dd.MM.YY' }}</td>
    <ng-container *ngIf="editMode">
      <td>
        <p-button
          *ngIf="(authenticatedUser$ | async)?.id === document.createdBy?.id && (allowedCategories && allowedCategories.length && allowedCategories.includes(document.category))"
          (click)="documentEdit(document)"
          icon="pi pi-pencil"
          [rounded]="true"
          [text]="true"></p-button>
      </td>
      <td *ngIf="!showUnlinkButton">
        <div
          [pTooltip]="document.readonly ? 'Das Dokument kann nicht gelöscht werden, da es bereits zur Prüfung eingereicht wurde!' : document.relations?.length > 0 ? 'Das Dokument kann nicht gelöscht werden, da es noch ' + document.relations?.length + ' mal verlinkt ist. Bitte löschen Sie vorher die Verlinkungen.' : undefined">
          <p-button
            [disabled]="(authenticatedUser$ | async)?.id !== document.createdBy?.id || document.readonly || !allowedCategories.includes(document.category) || document.relations?.length > 0 "
            [rounded]="true"
            [text]="true"
            (click)="deleteFile(document)"
            icon="pi pi-trash"></p-button>
          <p>{{document.groupdCertificationId}}</p>
        </div>
      </td>
      <td *ngIf="showUnlinkButton">
        <p-button
          [pTooltip]="document.readonly ? 'Die Verknüpfung zu diesem Dokument kann in diesem Status nicht entfernt werden': undefined"
          [disabled]="(authenticatedUser$ | async)?.id !== document.createdBy?.id || document.readonly || !allowedCategories.includes(document.category) || document.relations?.length > 0  "
          icon="pi pi-minus"
          [rounded]="true"
          (click)="unlinkFileClick(document)"
          pTooltip="Verknüpfung lösen"
          [text]="true"></p-button>
      </td>
    </ng-container>
    <td>
      <div class="flex gap-2">
        <p-button
          icon="pi pi-download"
          [rounded]="true"
          [text]="true"
          (click)="download(document)"></p-button>
        <ng-container *ngIf="(document.relation??[]).length as relationsCount">
          {{relationsCount}} Beziehung{{relationsCount>1?'en':''}}
        </ng-container>
      </div>
    </td>
  </tr>
</ng-template>

<ng-template #addButtonTemplate let-document>
  <div class="flex gap-2">
    <eva-button
      [disabled]="multiple ? false : getCount(documentList, document?.category) > 0"
      i18n-pTooltip="@@file.choose"
      pTooltip="Datei auswählen"
      *ngIf="editMode && (allowedCategories.includes(document?.category) || !document)"
      data-cy="add-button"
      [type]="'icon-only'"
      [icon]="{name: 'pi pi-plus'}"
      (click)="multiple ? documentNew( document?.category ?? undefined ) : documentEdit( undefined, document?.category ?? undefined )"></eva-button>
  </div>
</ng-template>
