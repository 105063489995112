<ng-container *ngIf="auditorRequest">
  <i
    *ngIf="auditorRequest.reviewed"
    data-cy="reviewed-marker"
    style="color: var(--p-secondary-color)"
    class="pi pi-verified mr-2 ml-2 align-items-center flex text-2xl"
    i18n-pTooltip="@@auditor-request.reviewed"
    pTooltip="wurde überprüft"></i>
  <i
    data-cy="valid-marker"
    *ngIf="!(authenticatedUser?.role === roles.AUDITOR) && !auditorRequest.valid"
    style="color: red"
    class="pi pi-exclamation-circle mr-2 ml-2 align-items-center flex text-2xl"
    i18n-pTooltip="@@auditor-request.not-valid"
    pTooltip="Antwort auf Rückfrage fehlt noch"></i>
  <i
    data-cy="valid-marker"
    *ngIf="authenticatedUser?.role === roles.AUDITOR && actionNeeded"
    style="color: red"
    class="pi pi-exclamation-circle mr-2 ml-2 align-items-center flex text-2xl"
    [pTooltip]="actionMessage"></i>
</ng-container>
