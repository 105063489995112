/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';

import { PrimengModule } from './primeng.module';

import { ImagesComponent } from './images/images.component';

import { EvaLabelComponent } from './i18n/eva.label';

import { ButtonComponent } from './form.elements';

import {
  DialogModule,
  EditorComponent,
  ButtonComponent as EvaButtonComponent,
} from '@eva/ng-base';

import { DrawControlDirective } from '../map/directives/draw.control.directive';
import { EvaMapComponent } from '../map/map.component';

import { RouterModule } from '@angular/router';
import { LoadingDirective } from '../../directives/loading.directive';
import { ObserveVisibilityDirective } from '../../directives/observe-visibility.directive';
import { ChangeImageSizeFromWKSPipe } from '../../pipes/changeImageSizeFromWKS.pipe';
import { SecurePipe } from '../../pipes/image-secure.pipe';
import {
  SafeHtmlPipe,
  SafeStylePipe,
  SafeUrlPipe,
} from '../../pipes/sanitizer.pipe';
import { ScenarioEditComponent } from '../certification/scenario/edit/scenario-edit.component';
import {
  EvaLayoutContentDirective,
  EvaLayoutHeaderDirective,
  LayoutComponent,
} from '../layout/layout-component/layout.component';
import { LoadingBarComponent } from '../loading-bar/loading-bar.component';
import {
  AssignSitesComponent,
  EditSiteComponent,
  EditSitesComponent,
  NewSiteComponent,
  SitesOverviewContainerComponent,
  UploadSiteComponent,
  ViewSiteComponent,
} from '../site';
import { DisplayAuditorRequestNumber } from './auditor-request/auditor-request-number/auditor-request-number.pipe';
import { AuditorRequestDisplayActionStatusComponent } from './auditor-request/display-action-status/auditor-request-display-action-status.component';
import { DisplayRelationsComponent } from './auditor-request/display-relations/display-relations.component';
import { AuditorRequestDisplayStatusComponent } from './auditor-request/display-status/auditor-request-display-status.component';
import { AuditorRequestDisplayComponent } from './auditor-request/display/auditor-request-display.component';
import { AuditorRequestEditorComponent } from './auditor-request/editor/auditor-request-editor.component';
import { ListAuditorRequestsComponent } from './auditor-request/list/list-auditor-requests.component';
import { AuditorRequestMessagesComponent } from './auditor-request/messages/auditor-request-messages.component';
import { RelationItemComponent } from './auditor-request/relation-item/relation-item.component';
import { RelationSelectionComponent } from './auditor-request/relation-selection/relation-selection.component';
import { VerificationStatusSelectorComponent } from './auditor-request/verification-status-selector/verification-status-selector.component';
import { NewEditGroupCertDialogComponent } from './dialogs/new-edit-group-cert-dialog/new-edit-group-cert-dialog.component';
import { DocumentEditComponent } from './document-edit/document-edit.component';
import { DocumentGroupListComponent } from './document-group-list/document-group-list.component';
import { FileUploadComponent } from './document-list/file-upload/file-upload.component';
import { HistoryListComponent } from './history-list/history-list.component';
import { HtmlContentComponent } from './htmlContent/html-content.component';
import { IframeComponent } from './iframe/iframe.component';
import { LinkDocumentsDialogComponent } from './link-documents-dialog/link-documents-dialog.component';
import { NewDocumentsDialogComponent } from './new-documents-dialog/new-documents-dialog.component';
import { EditProjectComponent } from './project/edit/edit.project.component';
import { ProjectInfoComponent } from './project/info/project-info.component';
import { ShowStatusComponent } from './status/show-status/show-status.component';
import { UpdateStatusComponent } from './status/update-status/update-status.component';
import { UploadFormControlComponent } from './upload-form-control/upload-form-control.component';

/**
 * Holds all components which are used in multiple places
 */
@NgModule({
  declarations: [
    AssignSitesComponent,
    AuditorRequestEditorComponent,
    AuditorRequestDisplayComponent,
    AuditorRequestMessagesComponent,
    ButtonComponent,
    DrawControlDirective,
    EditSiteComponent,
    EditSitesComponent,
    EvaLabelComponent,
    EvaLayoutContentDirective,
    EvaLayoutHeaderDirective,
    EvaMapComponent,
    ImagesComponent,
    UploadFormControlComponent,
    LoadingBarComponent,
    LoadingDirective,
    LayoutComponent,
    LoadingBarComponent,
    LoadingDirective,
    NewSiteComponent,
    ObserveVisibilityDirective,
    ProjectInfoComponent,
    EditProjectComponent,
    SafeStylePipe,
    SafeUrlPipe,
    SafeHtmlPipe,
    SecurePipe,
    ChangeImageSizeFromWKSPipe,
    SitesOverviewContainerComponent,
    UploadFormControlComponent,
    UploadSiteComponent,
    ViewSiteComponent,
    RelationSelectionComponent,
    RelationItemComponent,
    VerificationStatusSelectorComponent,
    FileUploadComponent,
    DisplayRelationsComponent,
    DocumentGroupListComponent,
    DocumentEditComponent,
    NewDocumentsDialogComponent,
    LinkDocumentsDialogComponent,
    ListAuditorRequestsComponent,
    IframeComponent,
    ScenarioEditComponent,
    HtmlContentComponent,
    NewEditGroupCertDialogComponent,
    HistoryListComponent,
    UpdateStatusComponent,
    ShowStatusComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrimengModule,
    NgxMapLibreGLModule,
    EvaButtonComponent,
    EditorComponent,
    DialogModule,
    RouterModule,
    DisplayAuditorRequestNumber,
    AuditorRequestDisplayStatusComponent,
    AuditorRequestDisplayActionStatusComponent,
  ],
  exports: [
    DisplayAuditorRequestNumber,
    AssignSitesComponent,
    ButtonComponent,
    DrawControlDirective,
    EditSiteComponent,
    EditSitesComponent,
    EvaLayoutHeaderDirective,
    EvaMapComponent,
    EvaLabelComponent,
    EvaLayoutContentDirective,
    ImagesComponent,
    LayoutComponent,
    LoadingBarComponent,
    LoadingDirective,
    FileUploadComponent,
    LayoutComponent,
    EvaLayoutHeaderDirective,
    EvaLayoutContentDirective,
    ObserveVisibilityDirective,
    ProjectInfoComponent,
    EditProjectComponent,
    SafeStylePipe,
    SafeHtmlPipe,
    SecurePipe,
    ChangeImageSizeFromWKSPipe,
    SitesOverviewContainerComponent,
    SafeUrlPipe,
    UploadFormControlComponent,
    UploadSiteComponent,
    ViewSiteComponent,
    RelationSelectionComponent,
    RelationItemComponent,
    AuditorRequestDisplayComponent,
    AuditorRequestEditorComponent,
    VerificationStatusSelectorComponent,
    AuditorRequestMessagesComponent,
    DisplayRelationsComponent,
    DocumentGroupListComponent,
    DocumentEditComponent,
    NewDocumentsDialogComponent,
    LinkDocumentsDialogComponent,
    ListAuditorRequestsComponent,
    IframeComponent,
    ScenarioEditComponent,
    HtmlContentComponent,
    NewEditGroupCertDialogComponent,
    HistoryListComponent,
    UpdateStatusComponent,
    ShowStatusComponent,
    EvaButtonComponent,
    EditorComponent,
  ],
  providers: [],
})
export class SharedComponentsModule {}
