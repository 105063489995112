/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  accentColor = 'green';
  errorRed = 'red';
  color2Green = 'green';
  color2GreenLight = 'lemon';

  constructor() {
    this.load();
  }

  load(): void {
    const accentColorHelper = document.getElementById(
      'accentColorHelper'
    ) as Element;
    const errorRedColorHelper = document.getElementById(
      'errorRedColorHelper'
    ) as Element;
    const color2GreenHelper = document.getElementById(
      'color2GreenHelper'
    ) as Element;
    const color2GreenLightHelper = document.getElementById(
      'color2GreenLightHelper'
    ) as Element;

    if (accentColorHelper) {
      this.accentColor =
        window.getComputedStyle(accentColorHelper).backgroundColor;
    }

    if (errorRedColorHelper) {
      this.errorRed =
        window.getComputedStyle(errorRedColorHelper).backgroundColor;
    }

    if (color2GreenHelper) {
      this.color2Green =
        window.getComputedStyle(color2GreenHelper).backgroundColor;
    }

    if (color2GreenLightHelper) {
      this.color2GreenLight = window.getComputedStyle(
        color2GreenLightHelper
      ).backgroundColor;
    }
  }
}
