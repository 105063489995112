/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

/**
 * import all primeng modules needed in this project
 */
import { NgModule } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DataViewModule } from 'primeng/dataview';
import { DatePickerModule } from 'primeng/datepicker';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DrawerModule } from 'primeng/drawer';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { FloatLabelModule } from 'primeng/floatlabel';
import { GalleriaModule } from 'primeng/galleria';
import { IconFieldModule } from 'primeng/iconfield';
import { InplaceModule } from 'primeng/inplace';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PickListModule } from 'primeng/picklist';
import { PopoverModule } from 'primeng/popover';
import { ProgressBar } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectModule } from 'primeng/select';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SkeletonModule } from 'primeng/skeleton';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SplitterModule } from 'primeng/splitter';
import { TableModule } from 'primeng/table';
import { TabsModule } from 'primeng/tabs';
import { TagModule } from 'primeng/tag';
import { TextareaModule } from 'primeng/textarea';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';

@NgModule({
  imports: [
    InputGroupAddonModule,
    InputGroupModule,
    AccordionModule,
    AutoCompleteModule,
    BreadcrumbModule,
    ButtonModule,
    DatePickerModule,
    CardModule,
    ChartModule,
    CheckboxModule,
    DataViewModule,
    DialogModule,
    SelectModule,
    FileUploadModule,
    GalleriaModule,
    InplaceModule,
    InputTextModule,
    TextareaModule,
    MessagesModule,
    MessageModule,
    PickListModule,
    RadioButtonModule,
    DrawerModule,
    SliderModule,
    ToastModule,
    TooltipModule,
    TableModule,
    TreeModule,
    SplitterModule,
    ScrollPanelModule,
    BadgeModule,
    SelectButtonModule,
    TagModule,
    FieldsetModule,
    ChipModule,
    DividerModule,
    MultiSelectModule,
    ConfirmDialogModule,
    MenuModule,
    EditorModule,
    ListboxModule,
    SplitButtonModule,
    ToggleButtonModule,
    ProgressSpinnerModule,
    ProgressBar,
    MegaMenuModule,
    TreeTableModule,
    SkeletonModule,
    PanelMenuModule,
    FloatLabelModule,
    IconFieldModule,
    InputIconModule,
    TabsModule,
    PopoverModule,
  ],
  providers: [MessageService, ConfirmationService],
  exports: [
    AccordionModule,
    AutoCompleteModule,
    BreadcrumbModule,
    ButtonModule,
    DatePickerModule,
    CardModule,
    ChartModule,
    CheckboxModule,
    DataViewModule,
    DialogModule,
    SelectModule,
    FileUploadModule,
    GalleriaModule,
    InplaceModule,
    InputTextModule,
    TextareaModule,
    MessagesModule,
    MessageModule,
    PickListModule,
    RadioButtonModule,
    DrawerModule,
    SliderModule,
    ToastModule,
    TooltipModule,
    TableModule,
    TreeModule,
    SplitterModule,
    TimelineModule,
    ScrollPanelModule,
    BadgeModule,
    TagModule,
    SelectButtonModule,
    FieldsetModule,
    ChipModule,
    PanelModule,
    DividerModule,
    DividerModule,
    MultiSelectModule,
    ConfirmDialogModule,
    MenuModule,
    EditorModule,
    ListboxModule,
    SplitButtonModule,
    ToggleButtonModule,
    ProgressSpinnerModule,
    ProgressBar,
    MegaMenuModule,
    TreeTableModule,
    SkeletonModule,
    InputGroupAddonModule,
    InputGroupModule,
    PanelMenuModule,
    FloatLabelModule,
    IconFieldModule,
    InputIconModule,
    TabsModule,
    PopoverModule,
  ],
})
export class PrimengModule {}
