/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import { Injectable } from '@angular/core';
import { DialogService, Severity } from '@eva/ng-base';
import { MessageService } from 'primeng/api';
import { Message, SEVERITY } from '../model/message';

@Injectable()
export class MessagingService {
  constructor(
    private messageService: MessageService,
    private dialogService: DialogService
  ) {}

  public error(summary: string, message: string, showErrorNumber = true) {
    let detail = message;
    if (showErrorNumber) {
      detail +=
        ' (Fehler Nr. ' +
        new Date().getTime().toString().substring(3, 12) +
        ')';
    }
    this.messageService.add({
      severity: SEVERITY.ERROR,
      summary,
      detail,
      sticky: true,
    });
  }

  public warn(summary: string, detail: string, sticky = true) {
    this.messageService.add({
      severity: SEVERITY.WARN,
      summary,
      detail,
      sticky,
    });
  }

  public success(summary: string, detail: string, sticky = false) {
    this.messageService.add({
      severity: SEVERITY.SUCCESS,
      summary,
      detail,
      sticky,
      closable: true,
    });
  }

  public add(message: Message) {
    if (message.severity === SEVERITY.DELETE) {
      message.severity = SEVERITY.ERROR;
    }

    this.messageService.add(message);
  }

  public confirm(
    summary: string,
    detail: string,

    callback: (arg: boolean) => void,
    severity = SEVERITY.INFO,
    acceptLabel = 'Ja',
    rejectLabel = 'Nein',

    icon = 'pi pi-exclamation-triangle'
  ) {
    const s =
      severity === SEVERITY.SUCCESS ||
      severity === SEVERITY.ERROR ||
      severity === SEVERITY.INFO
        ? null
        : this.getPrimeNGSeverity(severity as SEVERITY);

    const ref = this.dialogService.openConfirmDialog({
      styleClass: `dialog-${s}`,
      closable: false,
      focusOnShow: true,
      header: summary,
      data: {
        severity: s,
        message: detail,
        cancel: true,
        acceptLabel,
        rejectLabel,
      },
    });
    ref.onClose.subscribe((confirmed) => callback(confirmed));
  }

  public confirmOnly(
    summary: string,
    detail: string,
    callback: (arg: boolean) => void,
    severity = 'warn',
    style = {}
  ) {
    const ref = this.dialogService.openConfirmDialog({
      header: summary,
      styleClass: `dialog-${severity}`,
      closable: false,
      style: { maxWidth: '50vw', ...style },
      data: {
        severity: this.getPrimeNGSeverity(severity as SEVERITY),
        message: detail,
      },
    });
    ref.onClose.subscribe(callback);
  }

  public showMessage(summary: string, detail: string, severity = 'info') {
    this.messageService.clear();
    this.messageService.add({
      key: 'c',
      sticky: true,
      severity,
      summary,
      detail,
      closable: true,
    });
  }

  public onConfirm(callback: (i: boolean) => void) {
    this.messageService.clear();
    callback(true);
  }

  public onReject(callback: (i: boolean) => void) {
    this.messageService.clear();
    callback(false);
  }

  clear() {
    this.messageService.clear();
  }

  public getPrimeNGSeverity(s: SEVERITY | undefined): Severity {
    switch (s) {
      case SEVERITY.ERROR:
      case SEVERITY.DELETE:
        return 'danger';
      case SEVERITY.WARN:
        return 'warn';
      case SEVERITY.SUCCESS:
        return 'success';
      case SEVERITY.INFO:
        return 'info';
      default:
        return 'info';
    }
  }
}
