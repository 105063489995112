/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { Observable, delay, filter, from, of, switchMap, tap } from 'rxjs';
import { LoadAuthenticatedUserAction } from '../actions';
import { Claim } from '../model/claim';
import { AppState } from '../states/app.state';
import { EnvironmentService } from './environment.service';

export enum LOGOUT_REASON_CODES {
  USER_LOGOUT = 'user-logout',
  EMAIL_NOT_VERIFIED = 'email-not-verified',
  APPROAVAL_MISSING = 'approaval-missing',
  IDLE_TIMEOUT_REACHED = 'timeout',
  USER_NOT_FOUND = 'user-not-found',
  UNKNOWN = 'unknown',
}

/**
 * initialize Authentication Service based on
 * passed configuration argument
 */
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  hasPermissionToUseApp = false;
  hasBearerToken = false;

  isOffline = false;

  onlineStatusChecked = false;

  constructor(
    private environmentService: EnvironmentService,
    private oauthService: OAuthService,
    private store: Store
  ) {}

  /** function for one-time initialization in the app module*/
  initialize(): Observable<boolean> {
    if (window.location.href.indexOf('/unauthorized') > -1) {
      return of(true);
    } else {
      this._configureOidc();
      this.oauthService.setupAutomaticSilentRefresh();

      let result = new Observable<boolean>();
      if (!this.oauthService.hasValidIdToken()) {
        try {
          result = from(this.oauthService.loadDiscoveryDocumentAndLogin());
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          if (err.type === 'invalid_nonce_in_state') {
            // see https://github.com/manfredsteyer/angular-oauth2-oidc/issues/728
            result = from(this.oauthService.loadDiscoveryDocumentAndLogin());
          }
        }
      } else {
        result = from(this.oauthService.loadDiscoveryDocument()).pipe(
          switchMap((e) => of(true))
        );
      }

      return result.pipe(
        delay(
          this.environmentService.getValue('localAuth') === true ? 1000 : 0
        ),
        filter((r) => r),
        tap((r) => {
          this.hasBearerToken = true;
        }),
        tap(() => this.store.dispatch(new LoadAuthenticatedUserAction())),
        switchMap(() => of(true))
      );
    }
  }

  getClaim(key: string): string {
    const claim = this.oauthService.getIdentityClaims();
    return claim[key] ?? '';
  }

  getClaims(): Claim {
    return this.oauthService.getIdentityClaims() as Claim;
  }

  getUserName(): string {
    console.debug(
      '{"Authorization": "Bearer ' + this.oauthService.getAccessToken() + '"}'
    );
    // console.debug(this.oauthService.getIdToken());
    const claims = this.oauthService.getIdentityClaims() as Claim;
    // console.debug(claims);
    return claims.name;
  }

  handleInvalidUserAccess() {
    const claim = this.getClaims();
    console.log('handleInvalidUserAccess', claim);

    if (!claim) {
      this.login();
    } else if (claim?.email_verified) {
      this.logoutWithReason(LOGOUT_REASON_CODES.APPROAVAL_MISSING);
    } else {
      this.logoutWithReason(LOGOUT_REASON_CODES.EMAIL_NOT_VERIFIED);
    }
  }

  async login() {
    this._configureOidc();
    await this.oauthService.loadDiscoveryDocumentAndLogin();
  }

  /**
   * Logout revokes all tokens,
   * deletes all storages and redirects
   * to auth0 logout to kill the current session
   *
   * @param redirectPath (optional)
   */
  logout(redirectPath?: string) {
    const isOffline = this.store.selectSnapshot<boolean>(
      (state: { app: AppState }) => state.app.isOffline
    );
    if (!isOffline) {
      console.log('Logout triggered');
      this.oauthService.logOut(true);
      window.location.href = this._getRedirectUrl(redirectPath);
    }
  }

  /**
   *
   * @param reason show a screen confirming the logout and
   * show why the user is logged out
   *
   */
  logoutWithReason(reason: LOGOUT_REASON_CODES) {
    this.oauthService.logOut(true);
    window.location.href =
      this._getUrlForPath('unauthorized') + '?reason=' + reason;
  }

  validUser() {
    return this.getClaims();
  }

  stopAutomaticRefresh() {
    console.log('stopAutomaticRefresh');
    this.oauthService.stopAutomaticRefresh();
  }

  setupAutomaticSilentRefresh() {
    console.log('setupAutomaticSilentRefresh');
    this.oauthService.setupAutomaticSilentRefresh();
  }

  /**
   *
   * @param redirectPath
   * @returns
   */
  private _getRedirectUrl(redirectPath?: string) {
    let postLogoutRedirectUri =
      this.environmentService.getValue('oidc').postLogoutRedirectUri;
    if (redirectPath) {
      postLogoutRedirectUri = this._getUrlForPath(redirectPath);
    }
    let url = this.environmentService.getValue('oidc').logoutUrl + '?';
    url +=
      redirectPath === 'silent'
        ? ''
        : 'returnTo=' + encodeURIComponent(postLogoutRedirectUri) + '&';
    url +=
      'client_id=' +
      encodeURIComponent(this.environmentService.getValue('oidc').clientId);
    return url;
  }

  private _getUrlForPath(path: string) {
    return (
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '') +
      '/' +
      path
    );
  }

  private async _configureOidc() {
    // ... then setup oidc and try to login
    const oidcDefaults: AuthConfig = {
      responseType: 'code',
      scope: 'openid profile email offline_access',
      showDebugInformation: !this.environmentService.getValue('production'),
    };
    const oidcConfig = {
      ...oidcDefaults,
      ...this.environmentService.getValue('oidc'),
    };
    this.oauthService.configure(oidcConfig);
    this.oauthService.events.subscribe((event) => {
      if (event.type === 'token_refresh_error' && !this.isOffline) {
        this.logout();
      }
    });
  }
}
