/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import { Injectable } from '@angular/core';
import {
  Action,
  NgxsOnChanges,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { catchError, take, tap } from 'rxjs/operators';

import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';

import {
  LoadAuthenticatedUserAction,
  LoginAction,
  LogoutAction,
  SetOfflineReadyAction,
  SetTimeoutAction,
  UpdateOrganisationAction,
} from '../actions';

import { AuthStateModel } from './auth.state';

import { UserService } from '../services/api/user.service';
import {
  AuthenticationService,
  LOGOUT_REASON_CODES,
} from '../services/authentication.service';
import { EnvironmentService } from '../services/environment.service';

import { UserFragment, UserList } from '@eva/data-access/graphql';
import {
  Certification,
  CertificationFragment,
  CertificationStatus,
  Document,
  GroupCertification,
  GrowthRegion,
  HistoryFragment,
  ListProject,
  Project,
  ProjectSimpleFragment,
  ScenarioSummary,
  Site,
  SystemMessage,
  TreeSpecies,
  User,
  UserSimple,
} from '@eva/data-access/shared';
import { dbService } from '../services/offline-db.service';

import { from, throwError } from 'rxjs';

import buildInfo from '../../assets/build.json';

import { StandardDefinitionInterface } from '@eva/certification/api';
import {
  CreateUserAction,
  DeleteUserAction,
  LoadUserAction,
  LoadUsersAction,
  PrepareOfflineStatusAction,
  RefreshTimerAction,
  SetOfflineAction,
  UpdateAuthenticatedUserAction,
  UpdateUserAction,
} from '../actions';

import { StandardDefinitionService } from '@eva/certification/service';
import {
  CreateSystemMessageAction,
  LoadSystemDataAction,
  UpdateSystemMessageAction,
} from '../actions/systemActions';
import { CertificationApiService } from '../services/api/certification.api.service';
import { FileService } from '../services/api/file.service';
import { ProjectService } from '../services/api/project.service';
import { SystemService } from '../services/api/system.service';
import {
  loadAuditorActions,
  loadBaselineActions,
  loadCertificationActions,
  loadDocumentActions,
  loadGroupCertificationActions,
  loadGrowthModelActions,
  loadImageActions,
  loadProjectActions,
  loadSiteActions,
} from './actions';
import { HistoryStateModel } from './history.state';

const storageKeyIdleStart = 'client-app-last-idle-start';

export interface AppStateModel {
  // static
  standardDefinitions: StandardDefinitionInterface[];
  systemMessages: SystemMessage[];
  appVersion: string;
  regions: GrowthRegion[];
  systemDocuments: Document[];
  treeSpecies: TreeSpecies[];

  // client related
  idleTimerID: number;
  loading: boolean;
  isOffline: boolean;
  prepareOffline: boolean;
  offlineReady: boolean;
  isTimeout: boolean;

  // current obects
  authenticatedUser: User | undefined;
  currentCertification?: Certification;
  currentGroupCertification?: GroupCertification;
  currentProject: Project | undefined;
  currentSite: Site | undefined;
  currentUser: User | null;

  // data lists
  baselineScenarios: ScenarioSummary[];
  growthmodelScenarios: ScenarioSummary[];
  histories: HistoryFragment[];
  projects: ListProject[];
  certifications: Certification[];
  users: UserList;
  errors: string[];
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    appVersion: '',
    authenticatedUser: undefined,
    currentUser: null,
    users: [],
    errors: [],
    idleTimerID: 0,
    loading: false,
    isOffline: false,
    prepareOffline: false,
    offlineReady: false,
    systemDocuments: [],
    systemMessages: [],
    isTimeout: false,
    standardDefinitions: StandardDefinitionService.getAll(),
    treeSpecies: [],
    regions: [],
    currentCertification: undefined,
    currentSite: undefined,
    currentGroupCertification: undefined,
    projects: [],
    certifications: [],
    currentProject: undefined,
    histories: [],
    baselineScenarios: [],
    growthmodelScenarios: [],
  },
})
@Injectable()
export class AppState implements AppStateModel, NgxsOnChanges {
  constructor(
    private userService: UserService,
    private authService: AuthenticationService,
    private environmentService: EnvironmentService,
    private systemService: SystemService,
    projectService: ProjectService,
    fileService: FileService,
    certificationService: CertificationApiService
  ) {
    loadAuditorActions(certificationService);
    loadBaselineActions(certificationService);
    loadCertificationActions(certificationService);
    loadDocumentActions(fileService);
    loadGrowthModelActions(certificationService);
    loadGroupCertificationActions(certificationService);
    loadImageActions(fileService);
    loadProjectActions(projectService);
    loadSiteActions(certificationService);
  }

  certifications: CertificationFragment[];

  histories: HistoryFragment[];

  appVersion = '';

  authenticatedUser: UserFragment;

  // reference to setTimeout() - enables canceling if timeout is extended
  idleTimerID = 0;

  loading = false;

  isOffline = false;

  prepareOffline = false;

  offlineReady = false;

  systemDocuments: Document[] = [];

  users: UserList = [];

  sites = [];

  currentUser = null;

  errors = [];

  standardDefinitions: StandardDefinitionInterface[] = [];

  treeSpecies: TreeSpecies[] = [];

  regions: GrowthRegion[] = [];

  currentCertification: Certification;

  currentSite: Site;

  currentGroupCertification: GroupCertification;

  baselineScenarios: ScenarioSummary[];
  growthmodelScenarios: ScenarioSummary[];

  historyState: HistoryStateModel;

  projects: ProjectSimpleFragment[];
  currentProject: Project | undefined;

  systemMessages: SystemMessage[];
  isTimeout: boolean;

  @Selector()
  static loading(state: AppStateModel) {
    return state.loading;
  }

  @Selector()
  static errors(state: AppStateModel) {
    return state.errors;
  }

  @Selector()
  static authenticatedUser(state: AppStateModel) {
    return state.authenticatedUser;
  }

  @Selector()
  static users(state: AppStateModel) {
    return state.users;
  }

  @Selector()
  static currentUser(state: AppStateModel) {
    return state.currentUser;
  }

  @Selector()
  static getState(state: AppStateModel) {
    return state;
  }

  @Selector()
  static getIsOffline(state: AppStateModel) {
    return state.isOffline;
  }

  @Selector()
  static getIsOfflineReady(state: AppStateModel) {
    return state.offlineReady;
  }

  @Selector()
  static systemDocuments(state: AppStateModel) {
    return state.systemDocuments;
  }

  @Selector()
  static systemMessages(state: AppStateModel) {
    return state.systemMessages;
  }

  @Selector()
  static getIsTimeout(state: AppStateModel) {
    return state.isTimeout;
  }

  @Selector()
  static baselineScenarios(state: AppStateModel) {
    return state.baselineScenarios;
  }

  @Selector()
  static growthmodelScenarios(state: AppStateModel) {
    return state.growthmodelScenarios;
  }

  @Selector()
  static standardDefinitions(state: AppStateModel) {
    return state.standardDefinitions;
  }

  @Selector()
  static projects(state: AppStateModel) {
    return state.projects;
  }

  @Selector()
  static currentProject(state: AppStateModel) {
    return state.currentProject;
  }

  @Selector()
  static certifications(state: AppStateModel) {
    return state.certifications;
  }

  @Selector()
  static currentCertification(state: AppStateModel) {
    return state.currentCertification;
  }

  @Selector()
  static currentGroupCertification(state: AppStateModel) {
    return state.currentGroupCertification;
  }

  @Selector()
  static currentSite(state: AppStateModel) {
    return state.currentSite;
  }

  @Selector()
  static currentIndications(state: AppStateModel) {
    return state.currentCertification?.indications;
  }

  /**
   * extend idle timeout after each change
   *
   * @param change
   */
  ngxsOnChanges(change: NgxsSimpleChange) {
    if (!this.isOffline) {
      this.refreshIdleTimeout();
      // console.log('ngxsOnChanges', change);
    }
  }

  private refreshIdleTimeout() {
    if (this.idleTimerID > 0) {
      clearTimeout(this.idleTimerID);
      // console.debug('Idle Timer refresh ' + new Date().toISOString());
    } else {
      // console.debug('Idle Timer started ' + new Date().toISOString());
    }
    const timeout = this.environmentService.getValue('idleTimeout') * 60 * 1000;
    if (!this.isOffline) {
      this.idleTimerID = Number(
        setTimeout(() => {
          const clientAppLastIdleStart = new Date(
            +(localStorage.getItem(storageKeyIdleStart) ?? '')
          );
          if (clientAppLastIdleStart) {
            const idleTime =
              new Date().getTime() - clientAppLastIdleStart.getTime();
            if (idleTime < timeout) {
              console.log(
                'no timeout cause clientAppLastRefreshIdle is set and up to date'
              );
              this.refreshIdleTimeout();
              return;
            }
          }

          console.log('Idle timeout reached: ' + new Date());
          if (!this.isOffline) {
            this.authService.logoutWithReason(
              LOGOUT_REASON_CODES.IDLE_TIMEOUT_REACHED
            );
          }
        }, timeout)
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parseError(err: any) {
    console.log(err);
    return Object.keys(err.error.errors).map(
      (key) => `${key} ${err.error.errors[key]}`
    );
  }

  @Action(RefreshTimerAction)
  refreshTimer(stateContext: StateContext<AppStateModel>) {
    localStorage.setItem(storageKeyIdleStart, new Date().getTime().toString());
    if (!stateContext.getState().isOffline) {
      this.refreshIdleTimeout();
    } else if (this.idleTimerID) {
      clearTimeout(this.idleTimerID);
    }
  }

  @Action(LoadUsersAction)
  loadUsers(stateContext: StateContext<AppStateModel>) {
    console.log(stateContext.getState());
    stateContext.patchState({ loading: true });
    return this.userService.loadUsers().pipe(
      tap((users) => {
        stateContext.patchState({ users, currentUser: null, loading: false });
      })
    );
  }

  @Action(LoadUserAction)
  loadUser(stateContext: StateContext<AppStateModel>, action: LoadUserAction) {
    console.log(stateContext.getState());
    return this.userService.loadUser(action.userId).pipe(
      tap((currentUser) => {
        stateContext.patchState({ currentUser });
      })
    );
  }

  @Action(DeleteUserAction)
  deleteUser(
    stateContext: StateContext<AppStateModel>,
    action: DeleteUserAction
  ) {
    return this.userService.delete(action.id).pipe(
      tap((user) => {
        stateContext.setState(
          patch({
            users: removeItem<UserSimple>(
              (userItem) => userItem.id === user?.id
            ),
          })
        );
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  @Action(CreateUserAction)
  createUser(
    stateContext: StateContext<AppStateModel>,
    action: CreateUserAction
  ) {
    stateContext.patchState({ loading: true });
    return this.userService.createUser(action.user).pipe(
      tap((user) => {
        if (user?.id) {
          stateContext.patchState({ currentUser: user, loading: false });
        }
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  @Action(UpdateUserAction)
  updateUser(
    stateContext: StateContext<AppStateModel>,
    action: UpdateUserAction
  ) {
    return this.userService.updateUser(action.user).pipe(
      tap((user) => {
        if (user?.id) {
          if (
            stateContext &&
            stateContext.getState()?.authenticatedUser?.id === user.id
          ) {
            stateContext.patchState({ authenticatedUser: user });
          }
          stateContext.patchState({ currentUser: user });
          stateContext.setState(
            patch({
              currentUser: user,
              users: updateItem<UserSimple>(
                (userItem) => userItem.id === user.id,
                user
              ),
            })
          );
        }
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  @Action(UpdateAuthenticatedUserAction)
  updateAuthenticatedUser(
    stateContext: StateContext<AppStateModel>,
    action: UpdateAuthenticatedUserAction
  ) {
    return this.userService.updateUser(action.user).pipe(
      tap((user) => {
        if (user?.id) {
          stateContext.patchState({ authenticatedUser: user });
          stateContext.setState(
            patch({
              authenticatedUser: user,
              users: updateItem<UserSimple>(
                (userItem) => userItem?.id === user.id,
                user
              ),
            })
          );
        }
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  @Action(UpdateOrganisationAction)
  updateOrganisation(
    stateContext: StateContext<AppStateModel>,
    action: UpdateOrganisationAction
  ) {
    return this.userService.updateOrganisation(action.input).pipe(
      tap((organisation) => {
        if (
          organisation &&
          organisation?.id ===
            stateContext.getState().authenticatedUser?.organisation?.id
        ) {
          stateContext.setState(
            patch({
              authenticatedUser: patch({
                organisation: organisation,
              }),
            })
          );
        }

        stateContext.setState(
          patch({
            users: updateItem<UserSimple>(
              (userItem) => userItem?.organisation?.id === organisation?.id,
              patch({ organisation: organisation })
            ),
          })
        );
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  /**
   * save state to DB
   * triggers LoadCertifications in Offline Service
   */
  @Action(PrepareOfflineStatusAction)
  prepareOfflineStatus(stateContext: StateContext<AppStateModel>) {
    console.log('PrepareOfflineStatusAction app state');
    this.prepareOffline = true; // needed for refresh timer action
    if (this.idleTimerID) {
      clearTimeout(this.idleTimerID);
    }
    stateContext.setState(patch({ prepareOffline: true }));
    dbService.table('appState').clear();
    return from(
      dbService.table('appState').put({
        ...stateContext.getState(),
        id: 'appState',
      })
    );
  }

  /**
   * set to true if the application is offline
   */
  @Action(SetOfflineAction)
  setOffline(
    stateContext: StateContext<AppStateModel>,
    action: SetOfflineAction
  ) {
    const currentState = stateContext.getState();
    if (
      (action.offline && !currentState) ||
      !currentState.authenticatedUser?.id
    ) {
      // state has to be restored from indexeddb
      if (this.idleTimerID) {
        clearTimeout(this.idleTimerID);
      }
      this.isOffline = true;
      return dbService
        .table('appState')
        .get('appState')
        .then((state: AppState) => {
          stateContext.patchState({ ...state, isOffline: true });
          return stateContext.getState();
        });
    }
    stateContext.patchState({
      isOffline: action.offline,
      prepareOffline: false,
      appVersion: buildInfo.version,
    });
    // save last state as "offline" in db for next restart
    return from(
      dbService
        .table('lastState')
        .put({
          offline: action.offline,
          appVersion: buildInfo.version,
          id: 'lastState',
        })
        .then(() => {
          return stateContext.getState();
        })
    );
  }

  /**
   * set to true if all necessary data is loaded
   */
  @Action(SetOfflineReadyAction)
  setOfflineReady(stateContext: StateContext<AppStateModel>) {
    stateContext.patchState({
      offlineReady: true,
      prepareOffline: false,
    });
    return from(
      dbService
        .table('lastState')
        .put({
          offline: true,
          appVersion: buildInfo.version,
          id: 'lastState',
        })
        .then(() => {
          return stateContext.getState();
        })
    );
  }

  @Action(LoadSystemDataAction)
  loadSystemData(stateContext: StateContext<AppStateModel>) {
    this.systemService.loadSystemData().pipe(
      tap((data) => {
        stateContext.setState(
          patch({
            systemDocuments: data?.getSystemData.documents,
            systemMessages: data?.getSystemData
              .systemMessages as SystemMessage[],
          })
        );
      })
    );
  }

  @Action(CreateSystemMessageAction)
  createSystemMessage(
    stateContext: StateContext<AppStateModel>,
    action: CreateSystemMessageAction
  ) {
    return this.systemService.createSystemMessage(action.input).pipe(
      tap((message) => {
        if (message?.createSystemMessage.id) {
          stateContext.setState(
            patch({
              systemMessages: append<SystemMessage>([
                message.createSystemMessage as SystemMessage,
              ]),
              loading: false,
            })
          );
        }
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  @Action(UpdateSystemMessageAction)
  updateSystemMessage(
    stateContext: StateContext<AppStateModel>,
    action: UpdateSystemMessageAction
  ) {
    const { id, input } = action.payload;
    return this.systemService.updateSystemMessage(id, input).pipe(
      tap((response) => {
        if (response?.updateSystemMessage.id) {
          stateContext.setState(
            patch({
              systemMessages: updateItem<SystemMessage>(
                (m) => m.id === response.updateSystemMessage.id,
                response.updateSystemMessage as SystemMessage
              ),
              loading: false,
            })
          );
        }
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  /**
   * not in use yet!
   * User is immediately redirected if not authenticated
   */
  @Action(LoginAction)
  login(stateContext: StateContext<AppStateModel>, action: LoginAction) {
    stateContext.patchState({ authenticatedUser: action.payload });
  }

  @Action(LoadAuthenticatedUserAction)
  getAuthenticatedUser(stateContext: StateContext<AppStateModel>) {
    stateContext.patchState({ loading: true });
    return this.userService.loadAuthenticatedUser().pipe(
      take(1), // needed to complete Observable
      tap((authenticatedUser) => {
        if (authenticatedUser) {
          stateContext.patchState({ authenticatedUser, loading: false });
        } else {
          this.authService.logoutWithReason(LOGOUT_REASON_CODES.USER_NOT_FOUND);
        }
      }),
      catchError((e) => {
        this.authService.logout();
        throw e;
      })
    );
  }

  @Action(LogoutAction)
  logout(ctx: StateContext<AuthStateModel>) {
    const state = ctx.getState();
    this.authService.logout();
    return { ...state, authenticatedUser: null };
  }

  @Action(SetTimeoutAction)
  setTimeout(
    stateContext: StateContext<AppStateModel>,
    action: SetTimeoutAction
  ) {
    stateContext.patchState({ isTimeout: action.isTimeout });
  }
}

/**
 * override currentGroupCertification in certificationState
 * and update certifications in projects in projectState
 */
export const updateGroupCertificationInAppStateContext = (
  stateContext: StateContext<AppState>,
  updatedGroupCertification: GroupCertification
) => {
  const state = stateContext.getState();
  stateContext.patchState({
    currentGroupCertification: updatedGroupCertification,
  });
  const certificationIds = updatedGroupCertification.certifications?.map(
    (c) => c.id
  );
  const updateProjectCertifications = (
    projects: Project[],
    certifications: Certification[]
  ) => {
    return projects.map((p) => {
      if (p.certifications) {
        return {
          ...p,
          certifications: p.certifications.map((c) => {
            if (certificationIds?.includes(c.id)) {
              const updated = certifications.find((ce) => ce.id === c.id);
              return updated ?? c;
            }
            return c;
          }),
        };
      }
      return p;
    });
  };
  const updatedProjects = updateProjectCertifications(
    state.projects,
    updatedGroupCertification.certifications
  );
  if (updatedProjects) {
    let updatedCertifications: Certification[] = [];
    if (state.certifications.length > 0) {
      const { certifications, ...groupPartial } = updatedGroupCertification;
      updatedCertifications = state.certifications.map((c) => {
        if (certificationIds?.includes(c.id)) {
          const updated = updatedGroupCertification.certifications.find(
            (ce) => ce.id === c.id
          );
          // extend group since it is needed in certifications list
          return {
            ...updated,
            group: groupPartial,
          } as Certification;
        }
        return c;
      });
    }
    stateContext.patchState({
      currentGroupCertification: updatedGroupCertification,
      projects: updatedProjects,
      certifications: updatedCertifications,
    });
  }
};

/**
 * override currentCertification in certificationState
 * and update certifications in projects in projectState
 */
export const updateCertificationInStateContext = (
  stateContext: StateContext<AppState>,
  updatedCertification: Certification
) => {
  const state = stateContext.getState();
  stateContext.patchState({
    currentCertification: updatedCertification,
  });
  const relatedProject = state.projects.find(
    (p) => p.id === updatedCertification.project?.id
  );

  if (relatedProject) {
    const certifications = relatedProject.certifications?.map((c) =>
      c.id === updatedCertification.id ? updatedCertification : c
    );
    const updatedProjects = state.projects.map((project) =>
      project.id === relatedProject.id
        ? { ...relatedProject, certifications }
        : project
    );
    if (updatedProjects) {
      stateContext.patchState({
        projects: updatedProjects,
      });
    }
  }
  if (state.certifications.length > 0) {
    stateContext.setState(
      patch({
        certifications: updateItem<Certification>(
          (item) => item.id === updatedCertification.id,
          updatedCertification
        ),
      })
    );
  }

  if (
    updatedCertification.group &&
    updatedCertification.group.id === state.currentGroupCertification?.id
  ) {
    stateContext.setState(
      patch({
        currentGroupCertification: patch({
          status: updatedCertification.group.status,
          certifications: updateItem<Certification>(
            (item) => item.id === updatedCertification.id,
            updatedCertification
          ),
        }),
      })
    );
  }

  // remove from current group certification
  if (updatedCertification.status === CertificationStatus.WITHDRAWAL) {
    stateContext.setState(
      patch({
        currentGroupCertification: patch({
          certifications: removeItem(
            (item) => item.id === updatedCertification.id
          ),
        }),
      })
    );
  }
};

/**
 * helper function to update and merge updated data
 * of a group certification into a child certification
 */
const _updateCertificationDataFromUpdatedGroupCertification = (
  cert: Certification,
  groupCertification: Partial<GroupCertification>
) => {
  const updated = groupCertification.certifications?.find(
    (c) => c.id === cert.id
  );
  let mergedAuditorRequests = cert.auditorRequests.filter(
    (ar) => !ar.groupCertificationId
  );
  if (groupCertification.auditorRequests) {
    // merge updated AuditorRequests into the child certification
    mergedAuditorRequests = [
      ...mergedAuditorRequests,
      ...groupCertification.auditorRequests,
    ];
  }
  let mergedDocuments = cert.documents.filter((d) => !d.groupCertificationId);
  if (groupCertification.documents) {
    // merge updated AuditorRequests into the child certification
    mergedDocuments = [...mergedDocuments, ...groupCertification.documents];
  }
  if (updated && updated.indications?.length > 0) {
    // only the updated indication is returned
    // after groupAuditorRequests updates
    const updatedIndication = updated.indications[0];
    return {
      ...cert,
      auditorRequests: mergedAuditorRequests,
      documents: mergedDocuments,
      status: updated.status,
      indications: cert.indications.map((indication) => {
        if (indication.id === updatedIndication.id) {
          return {
            ...updatedIndication,
          };
        } else {
          return indication;
        }
      }),
    };
  } else {
    return {
      ...cert,
      auditorRequests: mergedAuditorRequests,
      documents: mergedDocuments,
    };
  }
};

/**
 * if a group AuditorRequest was updated it will affect all
 * certifications in a group. All actions affecting group AuditorRequests
 * return an extended groupCertification with all direct group data
 * and child certifications with only including updated data
 * (like status, auditorRequests, indications)
 */
export const updatePartialGroupCertificationInState = (
  stateContext: StateContext<AppState>,
  groupCertification: Partial<GroupCertification>
) => {
  const state = stateContext.getState();
  stateContext.patchState({
    currentGroupCertification: {
      ...state.currentGroupCertification,
      status:
        groupCertification.status ?? state.currentGroupCertification.status,
      auditorRequests: groupCertification.auditorRequests ?? [],
      documents:
        groupCertification.documents ??
        state.currentGroupCertification.documents,
      certifications: state.currentGroupCertification.certifications.map(
        (cert) => {
          return _updateCertificationDataFromUpdatedGroupCertification(
            cert,
            groupCertification
          );
        }
      ),
    },
  });
  if (state.currentCertification) {
    stateContext.patchState({
      currentCertification:
        _updateCertificationDataFromUpdatedGroupCertification(
          state.currentCertification,
          groupCertification
        ),
    });
  }
};

/**
 * called when a group certification is fetched
 * to merge all group data that is relevant for
 * child certifications into the state
 */
export const mergeGroupdataToChildCertifications = (
  stateContext: StateContext<AppState>,
  groupCertification: Partial<GroupCertification>
) => {
  const state = stateContext.getState();

  if (state.currentGroupCertification) {
    stateContext.patchState({
      currentGroupCertification: {
        ...state.currentGroupCertification,
        certifications: state.currentGroupCertification.certifications.map(
          (cert) => {
            if (groupCertification.auditorRequests) {
              return {
                ...cert,
                auditorRequests: [
                  ...cert.auditorRequests.filter(
                    (ar) => !ar.groupCertificationId
                  ),
                  ...groupCertification.auditorRequests,
                ],
                documents: [
                  ...cert.documents.filter((d) => !d.groupCertificationId),
                  ...(groupCertification.documents ?? []),
                ],
              };
            }
            return cert;
          }
        ),
      },
    });
  }

  if (state.currentCertification) {
    stateContext.setState(
      patch({
        currentCertification: patch({
          ...state.currentCertification,
          // extends auditor requests of current certification with auditor requests from group certification
          auditorRequests: [
            ...state.currentCertification.auditorRequests.filter(
              (ar) => !ar.groupCertificationId
            ),
            ...(groupCertification.auditorRequests ?? []),
          ],
          // extends auditor requests of current certification with auditor requests from group certification
          documents: [
            ...state.currentCertification.documents.filter(
              (d) => !d.groupCertificationId
            ),
            ...(groupCertification.documents ?? []),
          ],
        }),
      })
    );
  }
};
