/**
 * @copyright
 * Copyright 2024 EVA Service GmbH
 */

import { EnvironmentProviders } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { definePreset, palette } from '@primeng/themes';
import Aura from '@primeng/themes/aura';
import { PrimeNGConfigType, providePrimeNG } from 'primeng/config';

export function evaThemeProviders(
  config?: PrimeNGConfigType
): EnvironmentProviders[] {
  const defaultPreset = definePreset(Aura, {
    semantic: {
      primary: palette('#29363b'),
      secondary: palette('#5a6e00'),
      yellow: palette('#fbc02d'),
      green: palette('#689f38'),
      blue: palette('#2196f3'),
      red: palette('#f44336'),
      cyan: palette('#00bcd4'),
      pink: palette('#e91e63'),
      indigo: palette('#4e5fbb'),
      teal: palette('#009688'),
      orange: palette('#ff9800'),
      bluegray: palette('#607d8b'),
      purple: palette('#9c27b0'),
      colorScheme: {
        light: {
          borderRadius: {
            none: '0',
            xs: '2px',
            sm: '4px',
            md: '4px',
            lg: '6px',
            xl: '6px',
          },
        },
      },
    },
  });

  const defaultConfig: PrimeNGConfigType = {
    theme: {
      preset: defaultPreset,
      options: {
        darkModeSelector: false,
      },
    },
    ripple: true,
  };

  return [
    provideAnimationsAsync(),
    providePrimeNG(config ? { ...defaultConfig, ...config } : defaultConfig),
  ];
}
