<p-table
  [value]="documents"
  [(selection)]="selectedDocuments"
  dataKey="id"
  [tableStyle]="{'min-width': '50rem'}"
  showGridlines>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 4rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th>Name</th>
      <th></th>
      <th>Größe</th>
      <th>Datum</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-document>
    <tr>
      <td>
        <p-tableCheckbox
          [name]="document.id"
          [value]="document"></p-tableCheckbox>
      </td>
      <td>{{ document.name }}</td>
      <td>
        @if (document.groupCertificationId) {
        <p-badge
          [value]="'G'"
          severity="info"
          pTooltip="Dokument einer Gruppenzertifizierung" />
        }
      </td>
      <td>{{ formatSize(document.file?.fileSize) }}</td>
      <td>{{ document.createdAt | date : 'dd.MM.YY' }}</td>
    </tr>
  </ng-template>
</p-table>
